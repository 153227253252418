import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useRef, useState, useEffect } from 'react';
import { Playground } from 'docz';
import { Select } from '../../dist/js/bolt';
import '../../dist/css/bolt-css.css';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "select"
    }}>{`Select`}</h1>
    <h2 {...{
      "id": "propriedades"
    }}>{`Propriedades`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Option extende as propriedades IMenuContent
export interface ISelectOption extends IMenuContent {
  id: number; // id de referência da posição do elemento no array
}

// opções válidas do dataset
export interface ISelectDataset
  extends Pick<IInputDataset, 'name' | 'label' | 'helpertext' | 'error' | 'disabled' | 'value'> {
  options: string | ISelectOption[];
}

export interface ISelectRef extends HTMLElement {
  dataset: Partial<Record<keyof ISelectDataset, string>>;
}

export interface ISelectProps {
  ref: ISelectRef; // elemento html com os dataset possíveis
  menuClasses?: string[]; // classes que serão adicionadas ao objeto Menu
}

// construtor
export interface ISelectConstructor {
  new (props: ISelectProps): ISelect;
}

export interface ISelect {
  readonly destroy: () => void; // método para desmontar o componente

  onSelect?: (selected: ISelectOption) => void; // evento disparado ao selecionar um item do menu, adiciona como argumento o objeto do tipo ISelectOption selecionado
}
`}</code></pre>
    <Playground __position={0} __code={'() => {\n  const [select, setSelect] = useState(null)\n  const [selected, setSelected] = useState(0)\n  //\n  const selRef = useRef()\n  //\n  useEffect(() => {\n    if (selRef.current && !select) {\n      setSelect(new Select({ ref: selRef.current }))\n    }\n    //\n    return () => {\n      if (select) {\n        select.destroy()\n        setSelect(null)\n      }\n    }\n  }, [selRef.current, select])\n  //\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap\">\n      <div\n        className=\"tw-w-full\"\n        ref={selRef}\n        data-label=\"Estado\"\n        data-options={JSON.stringify([\n          { label: \'selecione\', id: 0, disabled: true },\n          { label: \'paraná\', id: 1 },\n          { label: \'santa catarina\', id: 2 },\n          { label: \'são paulo\', id: 3 },\n        ])}\n        data-value={selected}\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useState,
      useEffect,
      Playground,
      Select,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [select, setSelect] = useState(null);
        const [selected, setSelected] = useState(0); //

        const selRef = useRef(); //

        useEffect(() => {
          if (selRef.current && !select) {
            setSelect(new Select({
              ref: selRef.current
            }));
          } //


          return () => {
            if (select) {
              select.destroy();
              setSelect(null);
            }
          };
        }, [selRef.current, select]); //

        return <div className="tw-flex tw-gap-4 tw-flex-wrap">
        <div className="tw-w-full" ref={selRef} data-label="Estado" data-options={JSON.stringify([{
            label: 'selecione',
            id: 0,
            disabled: true
          }, {
            label: 'paraná',
            id: 1
          }, {
            label: 'santa catarina',
            id: 2
          }, {
            label: 'são paulo',
            id: 3
          }])} data-value={selected}></div>
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      